import {
  selectOpenQuestions,
  setHistory
} from '../state/reducers/openQuestionSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { useLocation } from '@reach/router'

export const useSaveIsOpenQuestions = () => {
  const [openQuestions, setOpenQuestions] = useState({})
  const dispatch = useDispatch()
  const location = useLocation()
  const questionsHistory = useSelector(selectOpenQuestions)

  useEffect(() => {
    if (location && !location.state) {
      location.state = { key: 'firstLocation' }
    }
  }, [])

  useEffect(() => {
    location.state &&
      questionsHistory[location.state.key] &&
      setOpenQuestions(questionsHistory[location.state.key].openQuestions)
  }, [])

  useEffect(() => {
    dispatch(
      setHistory({
        openQuestions,
        prevLocation: location
      })
    )
  }, [location, openQuestions])

  return [openQuestions, setOpenQuestions]
}
