import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { Link } from '../../atoms/Link'

export const questionListFAQ = [
  {
    labelGA: 'isOnlineEffective',
    ask: 'Эффективно ли онлайн-консультирование?',
    ans: () => (
      <>
        <p>
          Да, эффективно. <br />
          <br />
          За последнее десятилетие было проведено много научных исследований,
          доказывающих высокую эффективность онлайн-консультирования. По многим
          показателям данный формат не уступает традиционной очной психотерапии,
          а по ряду — даже превосходит ее.
          <br />
          <br />
        </p>
        <p>Ссылки на исследования:</p>
        <ul>
          <li>
            <a
              href="https://www.thelancet.com/pdfs/journals/eclinm/PIIS2589-5370(20)30186-3.pdf"
              rel="noreferrer"
              target="_blank"
            >
              Сравнение эффективности когнитивно-поведенческой терапии при
              депрессивных расстройствах в онлайн-формате и в очном формате:
              систематический обзор и метаанализ
            </a>
          </li>
          <li>
            <a
              href="https://jamanetwork.com/journals/jamapsychiatry/article-abstract/2765960"
              rel="noreferrer"
              target="_blank"
            >
              Сравнение когнитивно-поведенческой терапии в онлайн-формате и в
              очном при тревоге о здоровье
            </a>
          </li>
          <li>
            <a
              href="https://bmcpsychiatry.biomedcentral.com/articles/10.1186/1471-244X-10-54"
              rel="noreferrer"
              target="_blank"
            >
              Сравнение эффективности когнитивно-бихевиоральной терапии
              панического расстройства в формате онлайн-терапии и в очном
              групповом формате
            </a>
          </li>
          <li>
            <a
              href="https://www.sciencedirect.com/science/article/pii/S0165032713005120"
              rel="noreferrer"
              target="_blank"
            >
              Сравнение эффективности когнитивно-бихевиоральной терапии
              депрессии в очном формате и в формате онлайн-терапии
            </a>
          </li>
          <li>
            <a
              href="https://www.ncbi.nlm.nih.gov/pubmed/20183695"
              rel="noreferrer"
              target="_blank"
            >
              Мета-анализ исследований эффективности онлайн-терапии депрессии
            </a>
          </li>
          <li>
            <a
              href="https://www.ncbi.nlm.nih.gov/pubmed/22052966"
              rel="noreferrer"
              target="_blank"
            >
              Результаты использования переписки в терапии хронических
              ментальных расстройств
            </a>
          </li>
          <li>
            <a
              href="https://www.ncbi.nlm.nih.gov/pubmed/22080492"
              rel="noreferrer"
              target="_blank"
            >
              Терапия тяжелых психических расстройств в формате специально
              разработанной системы переписки
            </a>
          </li>
        </ul>
      </>
    )
  },
  {
    labelGA: 'formats',
    ask: 'В каких форматах можно получить помощь психолога?',
    ans: () => (
      <>
        <p>Мы предлагаем 3 формата работы:</p>
        <ol>
          <li>переписка с психологом (синхронная и асинхронная);</li>
          <li>видеоконсультации;</li>
          <li>аудиоконсультации.</li>
        </ol>
        <br />
        <p>
          Если вы не знаете, какой формат вам больше подходит, то наш специалист
          по подбору с радостью поможет вам выбрать оптимальный вариант.
        </p>
      </>
    )
  },
  {
    labelGA: 'individualFamily',
    ask: 'Вы работаете только индивидуально или с семьями тоже?',
    ans: () => (
      <>
        <p>
          Мы работаем как индивидуально, так и с партнерами, и с целыми семьями.
          А ещё проводим детско-родительские консультации.
        </p>
        <br />
        <p>
          Консультации с парами и семьями с детьми проводятся только по видео и
          длятся 50 минут. Стоимость одной такой консультации — 3 500 рублей.
        </p>
      </>
    )
  },
  {
    labelGA: 'childrenTeenagers',
    ask: 'А с детьми и подростками работаете?',
    ans: () => (
      <p>
        Мы консультируем только людей старше 18 лет. Если помощь нужна вашему
        ребёнку, то мы можем провести для вас детско-родительскую консультацию,
        на которой будете одновременно присутствовать вы и ваш ребенок. Если
        помощь нужна вам и при этом вам меньше 18 лет, то рекомендуем обратиться
        на бесплатный ресурс{' '}
        <a
          href="https://www.твоятерритория.онлайн"
          rel="noreferrer"
          target="_blank"
        >
          Твоя&#160;территория
        </a>{' '}
        или позвонить на телефон доверия для детей и подростков{' '}
        <a href="tel:+78002000122" rel="nofollow">
          +7&#160;(800)&#160;2000&#8209;122.
        </a>
      </p>
    )
  },

  {
    labelGA: 'whichQuestions',
    ask: 'С какими вопросами вы можете мне помочь?',
    ans: () => (
      <>
        <p>
          В команде YouTalk работают психологи самых разных специализаций,
          поэтому вы смело можете обращаться к нам с любыми вопросами.
        </p>
        <br />
        <p>Вот самые популярные темы:</p>
        <ul>
          <li>мысли и эмоции, внутренние переживания, отношения с собой;</li>
          <li>одиночество, отсутствие отношений;</li>
          <li>отношения с партнёром / партнёрами;</li>
          <li>семья, отношения с родственниками;</li>
          <li>детско-родительские вопросы;</li>
          <li>беременность, роды и материнство;</li>
          <li>горе, утраты, потери;</li>
          <li>насилие, травмы;</li>
          <li>тело, питание, психосоматика;</li>
          <li>вредные привычки и зависимости;</li>
          <li>работа, учёба, личная эффективность, саморазвитие;</li>
          <li>принятие важного решения, изменения в жизни.</li>
        </ul>
      </>
    )
  },

  {
    labelGA: 'therapist',
    ask: 'Чем психолог отличается от психиатра и психотерапевта?',
    ans: () => (
      <p>
        Психиатр — это врач (тот, кто получил медицинское образование), который
        занимается фармацевтическим лечением (может выписывать лекарства),
        ставит диагнозы и выдаёт справки (например, больничный лист). Психолог —
        специалист с психологическим образованием («психология» или «клиническая
        психология»), который занимается психологическим консультированием и
        психодиагностикой.
        <br />
        По законам РФ официально психотерапевтами могут называться только врачи,
        которые получили дополнительное психотерапевтическое образование
        (врач-психотерапевт, медицинский психотерапевт), но обычно
        психотерапевтами называют и психологов, которые получили дополнительное
        психотерапевтическое образование (например, гештальт-терапевт или
        когнитивный психотерапевт).
        <br />В нашем сервисе работают именно такие специалисты — немедицинские
        психотерапевты.
      </p>
    )
  },
  {
    labelGA: 'doctor',
    ask: 'А есть ли у вас в сервисе врачи? Могу ли я получить рецепт на лекарства или заключение?',
    ans: () => (
      <p>
        Врачей в YouTalk нет, но при необходимости наши специалисты по подбору и
        психологи смогут порекомендовать вам врачей, которым мы доверяем. Также
        наши психологи не уполномочены выдавать любого вида заключения или
        справки.
      </p>
    )
  },
  {
    labelGA: 'chatVSVideo',
    ask: 'Тариф «неделя переписки» длиннее, чем видеоконсультация?',
    ans: () => (
      <p>
        По объёму работы неделя асинхронной переписки фактически равна одной
        видео- или аудиоконсультации, так как психолог будет отвечать вам
        несколько раз в день, а не находиться на связи постоянно. Таким образом,
        работа в течение 5 дней переписки позволяет обсудить примерно столько
        же, сколько и 50-минутная консультация. Такой формат действительно
        комфортнее для тех, у кого нет возможности выделить отдельное время или
        удобное место для консультации, но не является более длительным или
        терапевтичным по сравнению с видео- или аудиоконсультациями.
      </p>
    )
  },
  {
    labelGA: 'pack',
    ask: 'Можно ли сначала взять одну консультацию, а потом пакет? ',
    ans: () => (
      <p>
        Да, конечно, вы можете попробовать работу, оплатив одну консультацию, а
        потом уже продолжить, оплатив пакет любого размера. Также можно
        оплачивать по одной консультации на протяжении всего процесса терапии
        или чередовать оплату пакетами с оплатами разовых консультаций.
      </p>
    )
  },
  {
    labelGA: 'noRequest',
    ask: 'А что, если у меня нет чёткого запроса?',
    ans: () => (
      <p>
        Это абсолютно нормально. Важная часть работы психолога — задать вам
        уточняющие вопросы и помочь сформулировать запрос. Скажем по секрету, во
        многих случаях запрос, который клиент озвучил в начале, в процессе
        консультирования изменился или был переформулирован. Поэтому можете
        смело начинать, даже если смутно представляете, о чём именно хотите
        поговорить.
      </p>
    )
  },

  {
    labelGA: 'profileFeedback',
    ask: 'В каком формате я получу информацию о психологе?',
    ans: () => (
      <p>
        Независимо от того, каким способом вы подбираете психолога, —
        самостоятельно или со специалистом по подбору — вы увидите его профиль.
        В нём указаны:
        <br />- Фотография, имя и возраст психолога
        <br />- Опыт консультирования (сколько лет психолог работает по
        профессии)
        <br />- Основные направления работы
        <br />- Форматы, в которых работает психолог, и цену на работу в каждом
        формате
        <br />- Образование психолога — учебное заведение, специальность и годы
        обучения
        <br />- Небольшая заметка психолога о себе и принципах своей работы
      </p>
    )
  },

  {
    labelGA: 'howMuch',
    ask: 'Сколько стоит консультирование в YouTalk?',
    ans: () => (
      <>
        <p>
          Для вашего удобства мы предлагаем несколько вариантов оплаты в
          зависимости от выбранного количества консультаций или недель
          переписки.
        </p>
        <br />
        <p>Асинхронная переписка:</p>
        <ul>
          <li>1 неделя — 2 500 рублей</li>
          <li>4 недели — 9 500 рублей</li>
          <li>8 недель — 18 600 рублей</li>
          <li>12 недель — 27 000 рублей</li>
        </ul>
        <br />
        <p>Видео- или аудиоконсультации:</p>
        <ul>
          <li>1 консультация — 2 500 рублей</li>
          <li>4 консультации — 9 500 рублей</li>
          <li>8 консультаций — 18 600 рублей</li>
          <li>12 консультаций — 27 000 рублей</li>
          <br />
          <li>Семейная консультация — 3 500 рублей</li>
          <li>Детско-родительская консультация — 3 500 рублей</li>
          <li>Синхронная переписка — 2500 рублей за 50 минут</li>
        </ul>
        <br />
        <p>
          При оплате из-за границы временно применяются цены в валюте.
          Актуальные тарифы в евро можно посмотреть{' '}
          <Link href="https://promo.youtalk.ru/prices-in-euro" target="_blank">
            здесь
          </Link>
          .
        </p>
      </>
    )
  },
  {
    labelGA: 'howToChat',
    ask: 'В какой форме происходит переписка с психологом?',
    ans: () => (
      <>
        <p>
          Консультирование в формате переписки бывает синхронным и асинхронным.
        </p>
        <br />
        <p>
          Синхронная переписка предполагает, что оба участника ведут диалог
          одновременно, как если бы собеседники сидели друг напротив друга и
          разговаривали.
        </p>
        <br />
        <p>
          Асинхронная переписка больше похожа на общение по почте, когда один
          человек пишет письмо, а второй отвечает на него через некоторое время.
          Порой ответ приходит сразу, а порой — в течение нескольких часов.
        </p>
        <br />
        <p>
          Консультирование YouTalk в течение 1 недели, 2-х недель, месяца или
          3-х месяцев происходит именно в формате асинхронной переписки, то есть
          вы пишете своему психологу когда и сколько захотите, а он отвечает вам
          минимум 1-2 раза в день.
        </p>
        <br />
        <p>
          Если вы хотите переписываться в режиме реального времени, то мы готовы
          предложить вам 50 минут синхронной переписки за 2 500 рублей.
        </p>
      </>
    )
  },
  {
    labelGA: 'howOften',
    ask: 'Как часто психолог YouTalk будет мне отвечать?',
    ans: () => (
      <>
        <p>
          Поскольку все наши психологи — это живые люди со своими семьями,
          другими клиентами и часто несколькими работами, каждый из них работает
          в своём собственном режиме.
        </p>
        <br />
        <p>
          Обычно психологи отвечают 1-2 раза в день 5 дней в неделю. Бывает, что
          клиенты договариваются с консультантом на какое-то определённое время
          и успевают немного пообщаться с ним в реальном времени.
        </p>
        <br />
        <p>
          Мы советуем обсудить пожелания и возможное расписание ответов в самом
          начале работы и выбрать вариант, который наиболее удобен как вам, так
          и вашему психологу.
        </p>
      </>
    )
  },
  {
    labelGA: 'howToStart',
    ask: 'Что нужно, чтобы начать?',
    ans: () => (
      <p>
        Вы можете подобрать психолога сами или с помощью специалиста по подбору.
        Если хотите сами:
        <br /> 1. Зайдите в{' '}
        <GatsbyLink target="_blank" to="/wizard/">
          алгоритм подбора
        </GatsbyLink>{' '}
        и ответьте на вопросы, он подумает и предложит подходящих психологов
        <br /> 2. Посмотрите профили психологов и оставьте заявку на работу с
        тем, кто понравится
        <br /> 3. Специалист по подбору психолога напишет вам в WhatsApp или
        SMS, подтвердит ваш выбор или предложит специалистов, которые лучше
        справятся с вашим запросом
        <br /> 4. Специалист пришлёт вам ссылку на оплату, после оплаты передаст
        ваш контакт психологу и дальше вы будете общаться с ним. О способах
        оплаты можете прочитать{' '}
        <GatsbyLink target="_blank" to="/sposoby-oplaty/">
          здесь
        </GatsbyLink>
        <br /> 5. Специалист по подбору психолога останется на связи, чтобы
        решать любые проблемы и выставлять ссылки на оплату следующих сессий
        <br />
        <br />
        Если хотите подобрать психолога с помощью специалиста по подбору,
        оставьте{' '}
        <GatsbyLink target="_blank" to="/form/">
          заявку
        </GatsbyLink>{' '}
        на сайте. В течение 24 часов он напишет в WhatsApp или SMS, уточнит
        запрос и предложит тариф. Затем пришлёт ссылку для оплаты сеанса,
        проанализирует ваш запрос и подберёт психолога. Психолог напишет, чтобы
        договориться о начале работы, а специалист по подбору останется на
        связи, чтобы решать любые проблемы и выставлять ссылки на оплату
        следующих сессий
      </p>
    )
  },

  {
    labelGA: 'howLongToWait',
    ask: 'Через какое время психолог свяжется со мной?',
    ans: () => (
      <>
        <p>
          После того, как вы пообщались со специалистом по подбору психолога, и
          оплатили услуги, психолог напишет вам в выбранный мессенджер в течение
          1 дня, либо в тот день, который вы выберете. Если что-то идёт не так
          или возникли вопросы — обращайтесь к специалисту по подбору психолога,
          он поможет справиться с любой проблемой
        </p>
      </>
    )
  },
  {
    labelGA: 'selfService',
    ask: 'Могу ли я сам выбирать себе психолога?',
    ans: () => (
      <>
        <p>
          Да, можете. У нас есть два варианта подбора: с помощью{' '}
          <GatsbyLink target="_blank" to="/wizard/">
            алгоритма
          </GatsbyLink>{' '}
          и с помощью{' '}
          <GatsbyLink target="_blank" to="/form/">
            специалиста по подбору психолога.
          </GatsbyLink>{' '}
          У специалистов есть высшее психологическое образование и они хорошо
          знакомы с каждым психологом YouTalk, поэтому качество подбора будет
          выше
        </p>
      </>
    )
  },
  {
    labelGA: 'sameOrDifferent',
    ask: 'Я буду работать с одним психологом или с разными?',
    ans: () => (
      <>
        <p>
          Вы работаете с тем психологом, которого выбрали. Никто кроме вас и
          вашего психолога не будет иметь доступа к тому, о чём вы говорите на
          сессиях, потому что они проходят не на нашей платформе, а в привычных
          вам мессенджерах или сервисах видеосвязи.
        </p>
      </>
    )
  },
  {
    labelGA: 'changePsy',
    ask: 'Могу ли я заменить психолога?',
    ans: () => (
      <>
        <p>
          В начале работы многие люди сомневаются, подходит ли им выбранный
          специалист. Для того чтобы понять это наверняка, нужно познакомиться с
          ним поближе. Поэтому, если вы только начали, рекомендуем дать своему
          психологу шанс и узнать друг друга получше.
        </p>
        <br />
        <p>
          Бывает и так, что специалист действительно не подходит — в таком
          случае заменим его, чтобы вы получили максимальный эффект от работы с
          YouTalk. Чтобы заменить психолога, напишите специалисту по подбору
        </p>
      </>
    )
  },
  {
    labelGA: 'arePsyProfessional',
    ask: 'Являются ли ваши психологи профессионалами?',
    ans: () => (
      <>
        <p>
          Да, конечно. Все наши специалисты имеют высшее психологическое
          образование, а также дополнительно обучались психотерапии. У каждого
          за плечами стаж консультирования от 3-х лет, опыт прохождения личной
          терапии и супервизии.
        </p>
        <br />
        <p>Каждый, кто попал в нашу команду:</p>
        <ul>
          <li>прошёл интервью и проверку дипломов;</li>
          <li>предоставил рекомендации старших коллег;</li>
          <li>сделал разбор терапевтического кейса;</li>
          <li>
            прошёл тест на толерантность и умение работать с уязвимыми группами.
          </li>
        </ul>
        <br />
        <p>
          Помимо этого, все наши психологи прошли внутреннее обучение YouTalk и
          постоянно повышают квалификацию. Они работают, соблюдая этический
          кодекс и стандарты консультирования YouTalk.
        </p>
      </>
    )
  },
  {
    labelGA: 'howLongLasts',
    ask: 'Сколько длится консультирование с YouTalk?',
    ans: () => (
      <>
        <p>Всё очень индивидуально и зависит от каждой конкретной ситуации.</p>
        <br />
        <p>
          Мы верим, что серьёзные изменения происходят в процессе долгосрочной
          работы и во многом зависят от глубины отношений с психологом. Тем не
          менее, мы знаем, что многим клиентам важно разобраться лишь с какой-то
          конкретной ситуацией и выработать план действий здесь и сейчас.
        </p>
        <br />
        <p>
          Наша команда психологов умеет работать в обоих форматах, поэтому
          длительность консультирования будет зависеть только от вашего запроса
          и пожеланий.
        </p>
      </>
    )
  },
  {
    labelGA: 'canAnonymous',
    ask: 'Могу ли я оставаться анонимным?',
    ans: () => (
      <>
        <p>
          Да.
          <br />
          Мы понимаем, что многие люди не обращаются к психологу из-за разного
          рода опасений, даже если помощь профессионала им крайне необходима.
          Наша ключевая задача — сделать доступ к консультированию на 100%
          комфортным и безопасным.
        </p>
        <br />
        <p>
          Поэтому мы не просим вас называть своё реальное ФИО. Вместо этого мы
          предлагаем вам самим определить степень предъявления и решить, как
          консультант будет к вам обращаться. Вы можете назвать ему своё
          настоящее имя, а можете придумать псевдоним — нас устроит любой
          вариант, который подходит вам.
        </p>
      </>
    )
  }
]
