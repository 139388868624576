/* eslint-disable max-len */
import React from 'react'

export const questionListMain = [
  {
    labelGA: 'HowMuchSessionCosts',
    ask: 'Сколько стоит и длится сессия?',
    ans: () => (
      <>
        <p>
          {/* eslint-disable-next-line max-len */}
          Цены на сессию с психологами YouTalk стартуют от 2 000 рублей. В
          анкете каждого специалиста указана стоимость консультации, его опыт и
          направления работы.
          <br />
          <br />
        </p>
        <p>
          Обычно одна сессия длится 50 минут. Вы можете записаться на сдвоенную
          консультацию, если чувствуете, что вам нужно больше времени.
        </p>
      </>
    )
  },
  {
    labelGA: 'HowToPaySessions',
    ask: 'Как нужно оплачивать сессии?',
    ans: () => (
      <>
        <p>
          {/* eslint-disable-next-line max-len */}
          Оплата проходит через системы эквайринга CloudPayments и CYPIX: на
          нашем сайте нужно ввести данные карты или Яндекс Пэй. Это быстро и
          безопасно. Средства поступят психологу только после сессии.
        </p>
      </>
    )
  },
  {
    labelGA: 'WhatIfIChangedPlans',
    ask: 'Что если у меня изменились планы, и я хочу отменить сессию?',
    ans: () => (
      <>
        <p>
          {/* eslint-disable-next-line max-len */}
          За 24 часа до начала сессию можно отменить бесплатно: внесенная сумма
          сохранится для оплаты следующей консультации. Однако при отмене сессии
          меньше чем за сутки спишется полная стоимость консультации, потому что
          на зарезервированное для вас время психолог не сможет назначить
          другого человека.
        </p>
      </>
    )
  },
  {
    labelGA: 'HowLongNeedTherapy',
    ask: 'Как долго нужно ходить к психологу?',
    ans: () => (
      <>
        <p>
          {/* eslint-disable-next-line max-len */}
          Универсального ответа здесь нет. Продолжительность терапии зависит от
          запроса, особенностей нервной системы, личностных качеств клиента, его
          мотивации и регулярности сессий. Это можно и нужно обсудить с
          психологом и узнать его прогноз. В любом случае решение, когда пора
          завершить терапию, остается за вами.
        </p>
      </>
    )
  },
  {
    labelGA: 'IAmHardWithProblem',
    ask: 'Мне сложно сформулировать свою проблему. Что делать?',
    ans: () => (
      <>
        <p>
          {/* eslint-disable-next-line max-len */}
          Это нормально. Нам бывает трудно разобраться в ворохе проблем, а
          иногда причина обращения к психологу скрывается за внешними поводами.
          Задача специалиста — помочь клиенту выявить истинные желания, мотивы
          поступков и сложности.
        </p>
      </>
    )
  },
  {
    labelGA: 'AllIsOKButImBad',
    ask: 'В моей жизни вроде все нормально, но мне плохо. Боюсь, что психолог не поймет меня.',
    ans: () => (
      <>
        <p>
          {/* eslint-disable-next-line max-len */}
          Чувства не подводят: если вам кажется, то вам не кажется. Даже если с
          внешней стороной жизни порядок, человеку может быть плохо из-за
          болезненных событий в прошлом. Психолог уделяет большое внимание
          эмоциональной сфере: учит клиента распознавать свои чувства,
          осознавать их причину и роль при общении с другими людьми.
        </p>
      </>
    )
  },
  {
    labelGA: 'IAmHardWithOpeningToPeoples',
    ask: 'Мне сложно открываться незнакомым людям. Это несовместимо с терапией?',
    ans: () => (
      <>
        <p>
          {/* eslint-disable-next-line max-len */}
          Базовые условия терапии — безопасность и конфиденциальность для
          клиента. Вы можете представиться любым именем и связываться с
          психологом без подключения камеры.
        </p>
      </>
    )
  },
  {
    labelGA: 'CanIRecordWithMyFriend',
    ask: 'Могу я записаться на сессию вместе с близким человеком?',
    ans: () => (
      <>
        <p>
          {/* eslint-disable-next-line max-len */}
          Да, в YouTalk действуют форматы «Парный» и «Вместе с ребенком». На
          сессию можно прийти с партнером или ребенком.
        </p>
      </>
    )
  },
  {
    labelGA: 'InPsychologyManyDirections',
    ask: 'В психологии столько всяких направлений — я понятия не имею, какое выбрать.',
    ans: () => (
      <>
        <p>
          {/* eslint-disable-next-line max-len */}
          Психологи YouTalk работают в 41 подходе, признанном научным
          сообществом. В нашем блоге мы писали об основных направлениях
          психотерапии, которые эффективны в разных ситуациях.
        </p>
      </>
    )
  },
  {
    labelGA: 'WhatIfIDontLikePsychologist',
    ask: 'А если мне не подойдет психолог или возникнут другие какие-то вопросы?',
    ans: () => (
      <>
        <p>
          {/* eslint-disable-next-line max-len */}
          Наши сотрудники из отдела клиентского сервиса на связи каждый день,
          включая выходные и праздники. Пишите нам в WhatsApp или Telegram:
          ответим на вопросы и решим проблемы.
        </p>
      </>
    )
  },
  {
    labelGA: 'ImproveYourLifeWithPsychologists',
    ask: 'Повысьте качество жизни с помощью онлайн-консультации с психологом',
    ans: () => (
      <>
        <p>
          {/* eslint-disable-next-line max-len */}
          Даже не сталкиваясь с серьезными ментальными проблемами, вы можете
          переживать психологические трудности, ухудшающие качество вашей жизни.
          В психотерапии возможно:
        </p>
        <ul>
          <li>
            <p>
              {/* eslint-disable-next-line max-len */}
              Улучшить самооценку, сделать её более устойчивой
            </p>
          </li>
          <li>
            <p>
              {/* eslint-disable-next-line max-len */}
              Успешно завершить сепарацию от родителей
            </p>
          </li>
          <li>
            <p>
              {/* eslint-disable-next-line max-len */}
              Научиться принимать критику, не испытывая боли
            </p>
          </li>
          <li>
            <p>
              {/* eslint-disable-next-line max-len */}
              Научиться распознавать свои эмоции и проживать их
            </p>
          </li>
          <li>
            <p>
              {/* eslint-disable-next-line max-len */}
              Улучшить общение с близкими
            </p>
          </li>
          <li>
            <p>
              {/* eslint-disable-next-line max-len */}
              Научиться выстраивать границы твёрдо, но не грубо
            </p>
          </li>
          <li>
            <p>
              {/* eslint-disable-next-line max-len */}
              Выбрать профессиональный или жизненный путь
            </p>
          </li>
        </ul>
        <p>
          {/* eslint-disable-next-line max-len */}
          Психологи YouTalk помогут разобраться и подскажут, как повысить
          качество жизни
        </p>
      </>
    )
  },
  {
    labelGA: 'WeWillHelpYouFindPsychologist',
    ask: 'Мы поможем вам найти и выбрать психолога для онлайн-психотерапии',
    ans: () => (
      <>
        <p>
          {/* eslint-disable-next-line max-len */}
          Наши специалисты по подбору — квалифицированные психологи. Изучив
          запрос и задав несколько вопросов, они подберут вам подходящего
          онлайн-психолога по множеству критериев: от подхода и тем, с которыми
          он работает, до нюансов терапии вроде директивности специалиста,
          домашних заданий и т.д. При самостоятельном поиске нередко можно
          сменить 3-4 специалиста до момента, когда вы найдёте действительно
          подходящего. В YouTalk 97% клиентов находят своего психолога с первого
          раза с помощью алгоритма или специалиста по подбору.
        </p>
      </>
    )
  },
  {
    labelGA: 'BenefitsOfYouTalk',
    ask: 'Преимущества онлайн-сервиса психологической помощи YouTalk',
    ans: () => (
      <ul>
        <li>
          <p>
            {/* eslint-disable-next-line max-len */}
            Терапия из любой точки мира
          </p>
        </li>
        <li>
          <p>
            {/* eslint-disable-next-line max-len */}
            Занимайтесь с психологом онлайн в любое удобное вам время, не выходя
            из дома
          </p>
        </li>
        <li>
          <p>
            {/* eslint-disable-next-line max-len */}
            Форматы на любой вкус
          </p>
        </li>
        <li>
          <p>
            {/* eslint-disable-next-line max-len */}
            Не всегда удобно общаться с психологом по видео. Поэтому в YouTalk
            есть формат аудиоконсультаций, синхронной и асинхронной переписки
          </p>
        </li>
        <li>
          <p>
            {/* eslint-disable-next-line max-len */}
            Квалифицированные психологи
          </p>
        </li>
        <li>
          <p>
            {/* eslint-disable-next-line max-len */}
            Все психологи YouTalk проходят строгий отбор на основе их
            образования, опыта работы, прохождения супервизий и личной терапии.
            Поэтому мы можем уверенно говорить, что наши специалисты могут
            оказать вам качественную и профессиональную помощь
          </p>
        </li>
        <li>
          <p>
            {/* eslint-disable-next-line max-len */}
            Точный подбор психолога
          </p>
        </li>
        <li>
          <p>
            {/* eslint-disable-next-line max-len */}
            Наши специалисты по подбору — квалифицированные психологи. Они
            уточнят ваш запрос и подберут специалиста, опираясь на десятки
            критериев. В YouTalk 97% клиентов находят своего психолога с первого
            раза
          </p>
        </li>
      </ul>
    )
  },
  {
    labelGA: 'HowOurOnlinePsychologistSelectionServiceWorks',
    ask: 'Как работает наш онлайн-сервис по подобру психолога',
    ans: () => (
      <>
        <p>
          {/* eslint-disable-next-line max-len */}В YouTalk вы можете выбрать
          психолога онлайн самостоятельно или воспользоваться помощью
          специалиста по подбору. Для этого нужно заполнить анкету, указав,
          какие проблемы вас беспокоят, и свои пожелания к психологу. В течение
          24 часов наш администратор напишет в WhatsApp, по SMS или на почту,
          уточнит запрос и предложит тариф и специалиста. После оплаты с вами
          свяжется психолог, и вы назначите дату первой консультации.
        </p>
      </>
    )
  },
  {
    labelGA: 'StartPsychotherapyRightNow',
    ask: 'Начните психотерапию с выбора подходящего психолога прямо сейчас',
    ans: () => (
      <>
        <p>
          {/* eslint-disable-next-line max-len */}
          Решиться на психотерапию может быть сложно, но мы здесь, чтобы помочь
          вам на этом пути. Чем раньше вы начинаете консультации с психологом,
          тем легче и быстрее вы сможете наладить самочувствие, повысить
          качество жизни и почувствовать себя целым
        </p>
      </>
    )
  }
]
