import React from 'react'

export const questionListCertificate = [
  {
    labelGA: 'expirationDate',
    ask: 'Как долго действует сертификат?',
    ans: () => (
      <p>
        Сертификат действует 6 месяцев с момента покупки (дата окончания
        действия указана на сертификате). Вы можете обратиться к нам в любое
        удобное время в течение этого периода.
      </p>
    )
  },
  {
    labelGA: 'formatChoice',
    ask: 'Кто выбирает формат занятий: тот, кто дарит, или кому дарят?',
    ans: () => <p>Тот, кому дарят.</p>
  },
  {
    labelGA: 'psyChoice',
    ask: 'Кто выбирает психолога?',
    ans: () => (
      <p>
        Наши специалисты по подбору вместе с вашим близким. Они зададут ему ряд
        уточняющих вопросов по анкете и предложат наиболее подходящего под
        запрос психолога.
      </p>
    )
  },
  {
    labelGA: 'promocodes',
    ask: 'Промокоды на скидку распространяются на сертификаты?',
    ans: () => <p>Если это оговорено в условиях акции.</p>
  }
]
