import React from 'react'
import { Button } from '../../atoms/Button'
import { Event as GAEvent } from '../../components/GA'

export const questionListAnxiety = [
  {
    labelGA: 'whyAnxietyEffective',
    ask: 'Почему важна психологическая помощь при тревоге?',
    ans: () => (
      <>
        <p>
          {/* eslint-disable-next-line max-len */}
          Психологическая помощь при тревоге — важная часть общей заботы о своем
          здоровье и благополучии. Тревога может оказывать негативное влияние на
          различные аспекты жизни — такие как отношения, работа, физическое
          здоровье и общее чувство благополучия. Вот несколько причин, по
          которым психологическая помощь важна при тревожности:
          <br />
          <br />
        </p>
        <ul>
          <li>
            <p>
              {/* eslint-disable-next-line max-len */}
              Профессиональный психолог может помочь справиться с сильными
              чувствами тревоги и страха, которые ограничивают жизнь человека и
              мешают ему достигать своих целей.
            </p>
          </li>
          <li>
            <p>
              {/* eslint-disable-next-line max-len */}
              При тревоге может происходить катастофизация реальности, что
              приводит к тому, что человек начинает слишком сильно переживать за
              некоторые события или воспринимать их негативно. Психологическая
              помощь помогает вернуть баланс и понимание реальности.
            </p>
          </li>
          <li>
            <p>
              {/* eslint-disable-next-line max-len */}
              Тревога может проявляться физически, как бессонница, повышенное
              сердцебиение, головокружение и т.д. Психологическая помощь может
              помочь уменьшить эти симптомы и улучшить общее физическое
              состояние.
            </p>
          </li>
          <li>
            <p>
              {/* eslint-disable-next-line max-len */}
              При тревоге может возникнуть чувство одиночества и непонимания.
              Психологическая помощь помогает справиться с этими чувствами и
              найти поддержку.
            </p>
          </li>
          <li>
            <p>
              {/* eslint-disable-next-line max-len */}
              Тревога может приводить к проблемам в личных и профессиональных
              отношениях. Психолог может помочь справиться с этими проблемами и
              улучшить качество жизни.
            </p>
          </li>
        </ul>
        <p>
          {/* eslint-disable-next-line max-len */}
          Психологическая помощь при тревоге помогает справиться с негативными
          эмоциями, улучшить физическое и психическое здоровье, а также качество
          жизни в целом. Если вы страдаете от тревоги, не стесняйтесь обратиться
          за профессиональной помощью психолога.
        </p>
        <br />
        <Button.NewOutline
          className="questions-list-item__ans--btn"
          href="/wizard/"
          onClick={() => GAEvent.openNavigate('anxietyLanding')}
          size="large"
          type="link"
        >
          Подобрать психолога
        </Button.NewOutline>
      </>
    )
  },
  {
    labelGA: 'whatAreAnxietyDisorders',
    ask: 'Какие бывают тревожные расстройства?',
    ans: () => (
      <>
        <p>В основном выделяют:</p>
        <br />
        <ul>
          <li>
            <p>Генерализованное тревожное расстройство</p>
          </li>
          <li>
            <p>
              {/* eslint-disable-next-line max-len */}
              ГТР не привязано к конкретным триггерам или местам, а возникает в
              разных местах и ситуациях. При этом тревога присутствует большую
              часть дней и сопровождается ещё несколькими симптомами.
            </p>
          </li>
          <li>
            <p>Специфические фобии</p>
          </li>
          <li>
            <p>
              {/* eslint-disable-next-line max-len */}
              Фобия — это сильный страх, связанный с конкретным триггером:
              объектом, ситуацией или местом. Например, арахнофобия — боязнь
              пауков, клаустрофобия — боязнь замкнутых пространств, акрофобия —
              боязнь высоты или гемофобия — боязнь крови.
            </p>
          </li>
          <li>
            <p>Паническое расстройство</p>
          </li>
          <li>
            <p>
              {/* eslint-disable-next-line max-len */}
              При паническом расстройстве регулярно возникают панические атаки.
              Это эпизоды сильной тревоги и паники, длящиеся от 10 минут до
              нескольких часов.
            </p>
          </li>
          <li>
            <p>Социальное тревожное расстройство (социальная фобия)</p>
          </li>
          <li>
            <p>
              {/* eslint-disable-next-line max-len */}
              Это тревога, связанная с ситуациями общения с людьми или другого
              контакта. Например, публичных выступлений, общения с кассирами,
              малознакомыми людьми и тп.
            </p>
          </li>
        </ul>
        <p>
          Подробнее про тревожные расстройства можно{' '}
          <a
            href="https://youtalk.ru/blog/kakie-byvayut-trevozhnye-rasstrojstva/"
            rel="noreferrer"
            target="_blank"
          >
            почитать&nbsp;в&nbsp;нашем&nbsp;блоге
          </a>
        </p>
      </>
    )
  },
  {
    labelGA: 'whatAreTheMainCausesOfPhobicAnxietyDisorders',
    ask: 'Какие основные причины тревожно-фобических расстройств?',
    ans: () => (
      <>
        <p>
          {/* eslint-disable-next-line max-len */}
          На развитие тревожных расстройств влияют биопсихосоциальные факторы в
          разной степени. То есть, у тревоги есть биологические причины
          (индивидуальные аспекты работы систем мозга), психологические
          (чувствительность к угрозам, особенности мышления) и социальные
          (травмирующие события, семейная ситуация в детстве, глобальные
          социальные проблемы).
          {/* eslint-disable-next-line max-len */}
          Невозможно однозначно сказать, в какой мере каждый из этих факторов
          влияет на развитие тревожных расстройств, так как у каждого человека
          собирается свой уникальный набор причин.
        </p>
      </>
    )
  },
  {
    labelGA: 'howEffectiveIsTreatmentForPhobicAnxietyDisorders',
    ask: 'Насколько эффективно лечение тревожно-фобических расстройств?',
    ans: () => (
      <p>
        {/* eslint-disable-next-line max-len */}
        Множество исследований показывают, что лечение тревожно-фобических
        расстройств эффективно и помогает значительно уменьшить симптомы. К
        примеру,{' '}
        <a
          href="https://www.researchgate.net/publication/6174762_Specificity_of_Treatment_Effects_Cognitive_Therapy_and_Relaxation_for_Generalized_Anxiety_and_Panic_Disorders"
          rel="noreferrer"
          target="_blank"
        >
          исследование&nbsp;2007&nbsp;года
        </a>{' '}
        {/* eslint-disable-next-line max-len */}
        показало, что когнитивно-поведенческая терапия (КПТ) является
        эффективным методом лечения тревожно-фобических расстройств и
        значительно уменьшает панику, страх тревоги и другие симптомы. Кроме
        того, исследования также показывают, что другие методы, такие как{' '}
        <a
          href="https://pubmed.ncbi.nlm.nih.gov/26116216/"
          rel="noreferrer"
          target="_blank"
        >
          релаксационные&nbsp;упражнения
        </a>
        ,{' '}
        <a
          href="https://pubmed.ncbi.nlm.nih.gov/19968674/"
          rel="noreferrer"
          target="_blank"
        >
          медитация&nbsp;и&nbsp;ароматерапия
        </a>
        , могут помочь уменьшить симптомы тревоги и фобий.
        {/* eslint-disable-next-line max-len */}
        Важно отметить, что эффективность лечения зависит от многих факторов,
        включая тяжесть симптомов, длительность заболевания, личные особенности
        и наличие дополнительных психических или физических заболеваний. В
        некоторых случаях необходимо совмещение психотерапии и приёма
        медикаментов, снижающих тревогу. Поэтому для каждого человека
        подбирается индивидуальный подход психологом и психиатром.
      </p>
    )
  },

  {
    labelGA: 'howToUnderstandThatAnxietyHasDevelopedIntoAnAnxietyDisorder',
    ask: 'Как понять, что тревога переросла в тревожное расстройство?',
    ans: () => (
      <>
        <p>
          {/* eslint-disable-next-line max-len */}
          Мы все иногда чувствуем тревогу, это естественная реакция на
          стрессовую ситуацию. Однако, если уровень тревожности не уменьшается
          со временем и начинает вмешиваться в повседневную жизнь, это может
          быть признаком тревожного расстройства.
        </p>
        <br />
        <p>
          {/* eslint-disable-next-line max-len */}
          Признаки, по которым можно отследить, что тревога выходит за рамки
          нормы:
        </p>
        <ol>
          <li>
            <p>
              {/* eslint-disable-next-line max-len */}
              Интенсивность и длительность тревоги. Тревога достигает такого
              уровня, что мешает заниматься обычными делами, или часто возникает
              без видимых причин, либо постоянно появляется в течение нескольких
              недель/месяцев.
            </p>
          </li>
          <li>
            <p>
              {/* eslint-disable-next-line max-len */}
              Физические симптомы. Часто с тревожными расстройствами связаны
              телесные проявления, такие как сердцебиение, потливость,
              головокружение, тошнота или боли в животе.
            </p>
          </li>
          <li>
            <p>
              {/* eslint-disable-next-line max-len */}
              Снижение качества жизни. Тревога начинает мешать работе, учёбе,
              отношениям с близкими или другими областям жизни.
            </p>
          </li>
          <li>
            <p>
              {/* eslint-disable-next-line max-len */}
              Социальная изоляция. Люди, страдающие от тревожного расстройства,
              часто избегают общения с другими людьми из-за страха, что что-то
              может пойти не так. Они испытывают сильный страх перед
              повседневными ситуациями: такими как поездка на работу или в
              магазин.
            </p>
          </li>
          <li>
            <p>
              {/* eslint-disable-next-line max-len */}
              Ночные кошмары. Люди с тревожным расстройством могут страдать от
              беспокойного сна, связанного с их страхами и беспокойством.
            </p>
          </li>
        </ol>
      </>
    )
  },

  {
    labelGA: 'doesOnlinePsychotherapyForPhobicAnxietyDisordersWork?',
    ask: 'Работает ли онлайн-психотерапия тревожно-фобических расстройств?',
    ans: () => (
      <p>
        {/* eslint-disable-next-line max-len */}
        Как и с другими психическими расстройствами, онлайн-психотерапия тревоги
        не уступает очному формату по эффективности. И открывает возможности,
        недоступные при формате лицом к лицу. Онлайн вы можете заниматься со
        специалистом, который будет наиболее эффективен в работе с вашей
        проблемой, даже если он находится на другом краю земли. При очной
        терапии ограничены уровнем и квалификацией специалистов из вашего
        города.
      </p>
    )
  },
  {
    labelGA: 'whyChooseYouTalktoCombatAnxiety',
    ask: 'Почему для борьбы с тревожностью стоит выбрать YouTalk?',
    ans: () => (
      <p>
        {/* eslint-disable-next-line max-len */}
        В YouTalk 169 психологов специализируются на работе по снижению тревоги
        и избавлению от тревожных расстройств в разных подходах и с
        разнообразным стилем работы.
        <br />
        <br />
        Уже более 2500 клиентов доверили нам свой путь по совладанию с тревогой.
        <br />
        <br />
        {/* eslint-disable-next-line max-len */}
        Наши специалисты по подбору учитывают все ваши запросы и пожелания к
        психологу — и благодаря этому более 97% клиентов находят своего
        терапевта с первого раза.
      </p>
    )
  },
  {
    labelGA: 'howToChooseAPsychologistToDealWithAnxiety',
    ask: 'Как выбрать психолога для борьбы с тревожностью?',
    ans: () => (
      <p>
        {/* eslint-disable-next-line max-len */}
        В первую очередь при выборе психолога важно найти специалиста по работе
        с вашим запросом. Это единственный критически важный критерий.
        <br />
        <br />
        {/* eslint-disable-next-line max-len */}В остальном вы можете опираться
        на свои предпочтения: какой возраст специалиста будет вам комфортен,
        важен ли пол специалиста, есть ли у вас пожелания по подходу и т.д.
      </p>
    )
  },
  {
    labelGA: 'theCostOfASessionWithAPsychologistAboutAnxiety?',
    ask: 'Стоимость сессии с психологом про тревогу?',
    ans: () => (
      <p>
        Индивидуальная онлайн-консультация с психологом на русском языке в любом
        формате в YouTalk стоит 2500 рублей. На английском языке — 3500. Тарифы
        на консультации при оплате в евро можно{' '}
        <a
          href="https://promo.youtalk.ru/prices-in-euro"
          rel="noreferrer"
          target="_blank"
        >
          посмотреть&nbsp;здесь
        </a>
      </p>
    )
  }
]
