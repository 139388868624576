import QuestionsListItem from '../../components/QuestionsListItem'
import React, { useCallback, useMemo } from 'react'
import { useQuestion as GAuseQuestion } from '../../components/GA'
import { questionListAnxiety } from './questionListAnxiety'
import { questionListCertificate } from './questionListCertificate'
import { questionListFAQ } from './questionListFAQ'
import { questionListMain } from './questionListMain'
import { questionListPsychologist } from './questionListPsychologist'
import { questionListSeksolog } from './questionListSeksolog'
import { questionListTariff } from './questionsListTariff'
import { useSaveIsOpenQuestions } from '../../atoms/useSaveIsOpenQuestions'

const QuestionListIterationComponent = ({
  labelGA,
  ask,
  ans,
  openQuestions,
  setOpenQuestions,
  onOpen,
  onlyOnOpenSendGA
}) => (
  <QuestionsListItem
    ans={ans()}
    ask={ask}
    onClick={useCallback(() => {
      if (openQuestions && !openQuestions[labelGA]) {
        return setOpenQuestions({
          ...openQuestions,
          [labelGA]: true
        })
      }
      return setOpenQuestions({
        ...openQuestions,
        [labelGA]: false
      })
    }, [openQuestions])}
    onOpen={onOpen}
    onlyOnOpenSendGA={onlyOnOpenSendGA}
    open={useMemo(
      () => openQuestions && openQuestions[labelGA],
      [openQuestions, labelGA]
    )}
  />
)

export const QuestionsList = ({
  page,
  className,
  eventGA,
  onlyOnOpenSendGA
}) => {
  const [openQuestions, setOpenQuestions] = useSaveIsOpenQuestions()
  const questionList =
    page === 'FAQ'
      ? questionListFAQ
      : page === 'Certificate'
      ? questionListCertificate
      : page === 'Seksolog'
      ? questionListSeksolog
      : page === 'Anxiety'
      ? questionListAnxiety
      : page === 'Main'
      ? questionListMain
      : page === 'Psychologist'
      ? questionListPsychologist
      : questionListTariff

  return questionList.map(({ labelGA, ask, ans }) => (
    <QuestionListIterationComponent
      key={labelGA}
      ans={ans}
      ask={ask}
      className={className}
      labelGA={labelGA}
      onOpen={GAuseQuestion({ event: eventGA, label: labelGA })}
      onlyOnOpenSendGA={onlyOnOpenSendGA}
      openQuestions={openQuestions}
      setOpenQuestions={setOpenQuestions}
    />
  ))
}
