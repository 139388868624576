import styled from 'styled-components'

export const Link = styled.a`
  && {
    display: inline-block;
    padding: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    font-weight: inherit;
    background-color: transparent;
    border: none;
    color: #2963a3 !important;
    text-decoration: underline;
  }
`
