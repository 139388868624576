/* eslint-disable max-len */
import React from 'react'
import attentionSrc from './icons/attention.svg'
import gearSrc from './icons/gear.svg'
import refreshSrc from './icons/refresh.svg'
import shieldSrc from './icons/shield.svg'

import styled from 'styled-components'

const Icon = styled.img`
  margin-right: 8px;
  height: 18px;
  width: 18px;
`

const Warning = styled.div`
  background-color: #fef3f2;
  border-radius: 8px;
  padding: 8px 8px 8px 40px;

  font-size: 14px;
  font-weight: 500;
  line-height: 20px;

  background-image: url('${attentionSrc}');
  background-repeat: no-repeat;
  background-position: 12px 16px;
`

const StyledList = styled.ul`
  padding: 0 !important;
  list-style: none !important;

  h5 {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 4px;
  }

  li {
    margin-bottom: 10px;
    margin-top: 10px;
  }
`

export const questionListPsychologist = [
  {
    labelGA: 'question1',
    ask: (
      <>
        <Icon alt="icon" src={gearSrc} />
        <>Как работает Youtalk</>
      </>
    ),
    ans: () => (
      <>
        <StyledList>
          <li>
            <h5>1. Выберите специалиста и время</h5>
            <p>
              Подберите психолога, который соответствует вашим предпочтениям и
              удобному времени для сессии.
            </p>

            <Warning>
              Вы можете иметь одного специалиста для каждого типа сессий —
              индивидуальных и семейных.
            </Warning>
          </li>
          <li>
            <h5>2. Забронируйте время</h5>

            <ul>
              <li>
                Если вы сами выбрали время: внесите полную оплату в течение 30
                минут после бронирования.
              </li>
              <li>
                Если время предложил психолог: оплатите сессию не позднее, чем
                за сутки до сессии.
              </li>
            </ul>
            <p>
              Своевременная оплата гарантирует, что ваше время останется
              зарезервированным. В случае задержки система, к сожалению,
              автоматически отменит бронирование.
            </p>
          </li>

          <li>
            <h5>3. Дождитесь сообщения от специалиста</h5>
            <p>
              {' '}
              Для обычных сессий психолог подтвердит сессию в течение 24 часов и
              свяжется с вами в WhatsApp. Для срочных сессий психолог подтвердит
              сессию и свяжется с вами в WhatsApp не менее чем за час до её
              начала.
            </p>
          </li>

          <li>
            <h5>4. Улучшайте ментальное здоровье</h5>
            <p>
              Назначайте сессии и пополняйте баланс в личном кабинете на любое
              количество баллов и любым удобным способом: российской картой,
              иностранной картой или сертификатом.
            </p>
          </li>
        </StyledList>
      </>
    )
  },
  {
    labelGA: 'question2',
    ask: (
      <>
        <Icon alt="icon" src={shieldSrc} />
        Бесплатная отмена или перенос сессии
      </>
    ),
    ans: () => (
      <>
        <p>
          Перенести или отменить сессию бесплатно можно только за 24 часа до
          назначенной сессии, иначе сессия подлежит оплате. Для переноса или
          отмены сессии напишите в службу поддержки.
        </p>
        <Warning>
          Важно: сессии с пометкой "молния" (срочные) нельзя отменить или
          перенести.
        </Warning>
      </>
    )
  },
  {
    labelGA: 'question3',
    ask: (
      <>
        <Icon alt="icon" src={refreshSrc} />
        Бесплатная смена психолога
      </>
    ),
    ans: () => (
      <>
        <p>
          Выбирать — это нормально. Психолога можно сменить в любой момент
          самостоятельно. Если возникнут трудности, обратитесь в службу
          поддержки. Мы поможем разобраться в ситуации и подобрать нового или
          дополнительного специалиста, например, семейного психолога или коуча.
        </p>
      </>
    )
  }
]
