import React from 'react'
import { Link } from 'gatsby'

export const questionListTariff = [
  {
    labelGA: 'howToPay',
    ask: 'Что нужно, чтобы оплатить консультацию?',
    ans: () => (
      <>
        <p>
          1. Подобрать психолога с помощью нашего{' '}
          <Link target="_blank" to="/wizard/">
            алгоритма
          </Link>{' '}
          <br />
          2. Выбрать подходящее время консультации
          <br />
          3. Пройти быструю регистрацию
          <br />
          4.Выбрать тип оплаты (с рублевой карты, валютной или сертификатом)
          <br />
          5. Оплатить выбранным способом
        </p>
      </>
    )
  },
  {
    labelGA: 'prepay',
    ask: 'Вы работаете по предоплате?',
    ans: () => (
      <>
        <p>
          Да, верно. Чтобы записаться на консультацию, вам нужно иметь
          необходимую сумму баллов на балансе.
        </p>
      </>
    )
  },
  {
    labelGA: 'sale',
    ask: 'А бывают ли у вас скидки?',
    ans: () => (
      <>
        <p>
          Да. У нас есть постоянные скидки на пакеты баллов. Также мы иногда
          устраиваем дополнительные промоакции. Следить за ними можно, например,
          в нашем{' '}
          <Link target="_blank" to="https://t.me/youtalktherapy">
            телеграм-канале
          </Link>{' '}
          .
        </p>
        <p>
          Обратите внимание, что все промокоды действуют на пополнение баланса,
          а не списание баллов с него. Условно, вы можете купить 2500 баллов за
          2000 рублей,но не можете списать с баланса 2000 баллов за консультацию
          вместо 2500.
        </p>
      </>
    )
  },
  {
    labelGA: 'averagePrice',
    ask: 'Сколько в среднем стоит консультация психолога за час?',
    ans: () => (
      <>
        <p>
          Сложно определить среднюю цену на консультацию психолога, т.к. влияет
          регион, опыт, формат терапии, подход специалиста и другие
          факторы.Подробнее о том, что влияет на цену консультации со
          специалистом, можно почитать в{' '}
          <Link target="_blank" to="https://youtalk.ru/blog/price/">
            статье.
          </Link>
        </p>
      </>
    )
  },
  {
    labelGA: 'priceOneSession',
    ask: 'Сколько стоит один сеанс с психологом Юток?',
    ans: () => (
      <>
        <p>
          Индивидуальная консультация с психологом на русском языке в любом
          формате в YouTalk стоит от 2000 рублей. Стоимость семейной
          консультации психолога, а также консультаций на английском языке можно
          посмотреть в карточке конкретного психолога. Тарифы на покупку баллов
          на консультации в евро можно посмотреть на{' '}
          <Link
            target="_blank"
            to="https://giftup.app/place-order/9ee7b9ca-6152-4bcc-8bef-08daa15859c7?platform=hosted"
          >
            странице покупки сертификатов.
          </Link>
        </p>
      </>
    )
  }
]
