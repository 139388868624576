import './index.scss'
import AnimateHeight from 'react-animate-height'
import React, { useCallback } from 'react'
import cn from 'classnames'
import { Icons } from '../../../youtalk-storybook/src/ui'
import { Title } from '../../atoms/Title'

const useShowAns = (onOpen, onClick, open, onlyOnOpenSendGA) => {
  // eslint-disable-next-line consistent-return
  const handleClick = useCallback(() => {
    if (!onOpen) return onClick()

    if (onlyOnOpenSendGA) {
      !open && onOpen()
      return onClick()
    }
    onOpen()
    onClick()
  }, [onOpen, onClick, open, onlyOnOpenSendGA])

  return handleClick
}

const QuestionsListItem = ({
  ask,
  ans = null,
  onOpen,
  onClick,
  open,
  onlyOnOpenSendGA = false
}) => {
  const handleClick = useShowAns(onOpen, onClick, open, onlyOnOpenSendGA)

  return (
    <li className="questions-list__item questions-list-item">
      <button
        className={cn('questions-list-item__tgl', {
          'questions-list-item__tgl_open': open
        })}
        onClick={handleClick}
      >
        <Title.H3 className="questions-list-item__title">{ask}</Title.H3>
        <span>{open ? <Icons.IconArrowUp /> : <Icons.IconArrowDown />}</span>
      </button>
      <AnimateHeight duration={200} height={open ? 'auto' : 0}>
        <div className="questions-list-item__ans">{ans}</div>
      </AnimateHeight>
    </li>
  )
}

export default QuestionsListItem
